import React, { createContext, useState } from 'react';
import { BiShoppingBag } from 'react-icons/bi';
import ShoppingCart from './ShoppingCart/ShoppingCart';

import useShoppingCart from './useShoppingCart';
import ProductDetail from './products/details/ProductDetail';

export const ShoppingCartContext = createContext();

const LayoutMain = ({ children }) => {
    const shoppingCart = useShoppingCart();
 

 
  return (

    <ShoppingCartContext.Provider value={shoppingCart}>


      <div className='custom-cursor'>
        {children}
      </div>
      <ShoppingCart />
    </ShoppingCartContext.Provider>

  );
}

export default LayoutMain;