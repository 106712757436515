import React from 'react'

export default function Footer() {
  return (
    <footer className='bg-black-plus'>
      <div class="container px-6 py-8 mx-auto">
        <div class="flex flex-col items-center text-center">
          <a href="1">
            <img class="w-auto h-32" src="./logo.png" alt="" />
          </a>

          <p class="max-w-md mx-auto mt-4 text-text-white-plus "></p>

          
        </div>

        <hr class="my-10 border-gray-200 dark:border-gray-700" />

        <div class="flex flex-col items-center sm:flex-row sm:justify-between">
          <p class="text-sm text-gray-500">© Copyright 2024. All Rights Reserved.</p>

          <div class="flex mt-3 -mx-2 sm:mt-0">
            <a href="0" class="mx-2 text-sm text-gray-500 transition-colors duration-300 hover:text-gray-500 dark:hover:text-gray-300" aria-label="Reddit"> Teams </a>

            <a href="0" class="mx-2 text-sm text-gray-500 transition-colors duration-300 hover:text-gray-500 dark:hover:text-gray-300" aria-label="Reddit"> Privacy </a>

            <a href="0" class="mx-2 text-sm text-gray-500 transition-colors duration-300 hover:text-gray-500 dark:hover:text-gray-300" aria-label="Reddit"> Cookies </a>
          </div>
        </div>
      </div>
    </footer>
  )
}
