import React from 'react'
import ComposedTitle from '../../../Components/ComposedTitle'
import { motion } from "framer-motion";
import { FaArrowAltCircleDown } from 'react-icons/fa';
import LinkBox from '../../../Components/link-box/LinkBox';
import { Si365Datascience, Si7Zip, SiAcm, SiStudyverse } from 'react-icons/si';

export default function SectionsSection() {
  const Sections = [
    {
      icon: <FaArrowAltCircleDown />,
      label: "MP",
    },
    {
      icon: <Si7Zip />,
      label: "PC",
    },
    {
      icon: <SiAcm />,
      label: "PT",
    },
  ]
  return (
    <div className='bg-packs-section border-b-8 border-C2'>
      <div className='2xl:max-w-7xl max-w-5xl m-auto py-24'>
        <ComposedTitle title={"Check our different"} subTitle={"sections"} />
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 mt-6 items-center justify-center w-full justify-items-center self-center">
          {Sections.map((sec, index) => (
            <motion.div whileHover={{ scale: 1.1, zIndex: 10 }} key={index} className="relative h-72 w-56 rounded-xl bg-gradient-to-br from-C1 to-C1/30   text-black-plus hover:text-white-plus hover:duration-500 duration-1000" style={{transformStyle: "preserve-3d", transform: "rotateX(0deg) rotateY(0deg)"}}>
            <div className="absolute inset-4 grid place-content-center rounded-xl bg-white shadow-lg gap-3 select-none hover:bg-C1 " style={{transform: "translateZ(75px)", transformStyle: "preserve-3d"}}>
              
              <p className="text-center text-4xl  font-black font-monta flex flex-col justify-center items-center uppercase gap-6" style={{transform: "translateZ(50px)"}}>{sec.icon} {sec.label}</p>
              </div>
              </motion.div>
          ))}
        </div>

          <div className='my-6 mx-auto w-full flex justify-center items-center'> 
        <LinkBox label={"Vous n'avez pas trouvé votre cible ?! dites-le nous !"} link={0} theme={'blue'} />
          </div>



      </div>
    </div>
  )
}

