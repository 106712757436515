import React from 'react'
import { motion } from "framer-motion";

const Box = ()=>{
    const words = ["PREPA", "ENGINEER","PREPA","PREPA","PREPA","ENGINEER","PREPA","PREPA","PREPA","PREPA", "ENGINEER","PREPA","PREPA","PREPA","ENGINEER","PREPA","PREPA","PREPA","PREPA","ENGINEER","PREPA","PREPA","PREPA","PREPA","ENGINEER","PREPA","PREPA","PREPA","PREPA","ENGINEER","PREPA","PREPA","PREPA","PREPA","ENGINEER","PREPA","PREPA","PREPA","PREPA"]
  return (
    <motion.div className='w-full rotate-3'
    initial={{x:-500}}
    animate={{translateX:-350}}
    layout
      transition={{ duration: 4.5, type: "spring",repeat: Infinity, repeatDelay: 1.2 , repeatType: "mirror" }}
    >
        <div className='bg-white border-C2 border-2 flex justify-between items-center gap-6 w-fit h-16 '>
        {words.map((word, index)=>{
            let color = ((word==='ENGINEER') ? 'text-C1' : 'text-C2');
             return(
                 <h1 key={index}
                 className={`${color} uppercase font-monta font-black text-5xl`}>{word}</h1>
             )
            })}
        </div>
    </motion.div>
  )
}
const Box2 = ()=>{
  const words = ["PREPA", "ENGINEER","PREPA","PREPA","PREPA","ENGINEER","PREPA","PREPA","PREPA","PREPA", "ENGINEER","PREPA","PREPA","PREPA","ENGINEER","PREPA","PREPA","PREPA","PREPA","ENGINEER","PREPA","PREPA","PREPA","PREPA","ENGINEER","PREPA","PREPA","PREPA","PREPA","ENGINEER","PREPA","PREPA","PREPA","PREPA","ENGINEER","PREPA","PREPA","PREPA","PREPA"]
return (
  <motion.div className='w-full -rotate-3'
  initial={{x:-500}}
  animate={{translateX:350}}
  layout
    transition={{ duration: 2.5, type: "spring",repeat: Infinity, repeatDelay: 2 , repeatType: "mirror" }}
  >
      <div className='bg-white border-C2 border-2 flex justify-between items-center gap-6 w-fit h-16 '>
      {words.map((word, index)=>{
          let color = ((word==='ENGINEER') ? 'text-C1' : 'text-C2');
           return(
               <h1 key={index}
               className={`${color} uppercase font-monta font-black text-5xl`}>{word}</h1>
           )
          })}
      </div>
  </motion.div>
)
}
export default function RotatedBox(){
    return(
       <div>
         <Box2 />
         <Box/>
       </div>
    )
}