import React, { useState, useEffect, useContext } from 'react';
import { FaCartPlus, FaArrowRight, FaMarkdown, FaAdjust, FaFileSignature } from 'react-icons/fa';
import LinkBox from '../../../Components/link-box/LinkBox';
import { TbFileDescription } from 'react-icons/tb';
import { useParams } from 'react-router-dom';

import { BiDetail } from 'react-icons/bi';
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import useShoppingCart from '../../../modules/useShoppingCart';
import LayoutMain, { ShoppingCartContext } from '../../LayoutMain';
import Footer from '../../../Components/footer/Footer';
import InfoBar from '../../../Components/header/info-bar/InfoBar';
import ShuffleHero from '../../../Components/header/shuffle-hero/ShuffleHero';

export default function ProductDetail() {
  const { id } = useParams();  // Get the id from the URL
  
  const [product, setProduct] = useState(null);
  const loadProduct = async () => {
    try {
      const response = await fetch('https://docuverse.tn/api/products/'+id, {
        method: 'GET', // Specify the HTTP method
        headers: {
          'Content-Type': 'application/json', 
        }});
      const data = await response.json();
      setProduct(data.product);
      setSimilarProducts(data.similar_products);
      setProductInShoppingCart(checkProductInShoppingCart(data.products_id))
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };
  const [similarProducts, setSimilarProducts] = useState(null);


  //ProductDetails.jsx
  const shoppingCart = useContext(ShoppingCartContext);
 

  const [productInShoppingCart, setProductInShoppingCart] = useState(false)
  const [popUp, setpopUp] = useState(false);

  const handleOpen = () => { setpopUp(!popUp) };
  


  useEffect( () => {
    loadProduct();
  }, [])

  const checkProductInShoppingCart = (id) => {
    let localCart = localStorage.getItem('shoppingCart');
    let shoppingCart = localCart ? JSON.parse(localCart) : [];
    return shoppingCart.find(i => i.products_id === id);
  }

  const handleOpenCart = () => {
    handleOpen(null);
    shoppingCart.toggleShoppingCart()
  };




  if (!product) {
    return <div>Loading...</div>;
  }

  const addToCart = () => {
    let shoppingCart = localStorage.getItem('shoppingCart');
     shoppingCart = shoppingCart ? JSON.parse(shoppingCart) : [];
     shoppingCart.push({...product, qte:1});
     localStorage.setItem('shoppingCart', JSON.stringify(shoppingCart))

     setProductInShoppingCart(checkProductInShoppingCart(product.products_id))

  }

  return (
    <LayoutMain >





      <InfoBar />
      <ShuffleHero home={false} title={'Product'} subTitle={'see All Products From Docuverse'} />
      <Dialog
        open={popUp}
        size={"xs"}
        handler={handleOpen}
      >
        <DialogHeader>Item Added Successfully !</DialogHeader>

        <DialogFooter>
          <Button
            variant="text"
            onClick={() => handleOpen(null)}
            className=" mr-1 bg-C1 text-white duration-300   py-2 px-4 transition-all hover:bg-C1/85 active:scale-95 rounded-2xl font-monta "
          >
            <span>Continue Shopping</span>
          </Button>
          <Button
            onClick={handleOpenCart}
            className="bg-white hover:text-white border-2 border-C1 text-C1 duration-300   py-2 px-4 transition-all hover:bg-C1/85 active:scale-95 rounded-2xl font-monta "
          >
            <span>Checkout</span>
          </Button>
        </DialogFooter>
      </Dialog>
      <div className="min-h-screen  bg-[#F8F8F8] font-monta p-6">
        <div className="max-w-6xl mx-auto  overflow-hidden">
          <div className="flex flex-col justify-between lg:flex-row">
            {/* Product Image and Preview */}
            <div className="lg:w-1/3 w-auto h-[570px] border-4 border-[#18AAF2]">
              <img
                src={"https://docuverse.tn/public/SiteImages/products/"+ product.main_pic_link}  
                alt={product.name}
                className="w-full h-full object-cover"
              />

            </div>

            {/* Product Details */}
            <div className="lg:w-7/12 lg:pt-0 pt-4">
              <div className="flex justify-betwee flex-col gap-5 items-start mb-4 bg-white product-section-shadow p-8">
                <div>
                  <h2 className="text-xl font-monta font-light">{product.category}</h2>
                  <h2 className="text-5xl font-monta font-bold">{product.name}</h2>
                  <p className="text-2xl font-monta font-medium text-black-plus pt-8">{product.promo_price ? <div><del>{product.price} TND</del> <span>{product.promo_price}</span> </div>: <span>{product.promo_price}</span>} TND</p>
                </div>
                <div className='flex gap-4 justify-start items-center'>
                  {productInShoppingCart ?
                    <button className="bg-black border-black-plus border-[1px] rounded-xl text-white-plus px-4 py-2  uppercase font-monta font-medium flex items-center">
                      Added To Cart !
                      <FaMarkdown className="ml-2" />
                    </button>

                    :
                    <button onClick={() => { addToCart(); handleOpen("xs") }} className="bg-transparent border-black-plus border-[1px] rounded-xl text-black-plus px-4 py-2  uppercase font-monta font-medium flex items-center">
                      AJOUTER AU PANIER
                      <FaCartPlus className="ml-2" />
                    </button>
                  }

                  <a href={product.free_preview_link} target="_blank" rel="noreferrer" className=" bg-blue-500 text-white px-4 py-2 rounded-xl hover:bg-blue-600 transition-colors flex  items-center gap-8">
                  Echantillons              <FaArrowRight />

                  </a>
                </div>
              </div>
              <div className="mb-4 bg-white product-section-shadow p-8">
                <h3 className="text-2xl  font-monta flex justify-start gap-2 items-center pb-2"> <TbFileDescription />
                  Description</h3>
                <p className="text-gray-700 ">{product.description}</p>
              </div>
              <div className='mx-auto w-full flex justify-center items-center'>
                <LinkBox label={"Didn’t find your target ?! tell us !"} link={'https://www.facebook.com/Docuverse01'} theme={'blue'} />
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row justify-between w-full gap-4 mt-2">
            <div className="w-full lg:w-7/12 mb-4 bg-white product-section-shadow p-8">
              <h3 className="text-2xl  font-monta flex justify-start gap-2 items-center pb-2"> <FaFileSignature />Démo vidéo</h3>
              <p className="text-gray-700">
              <iframe width="560" height="315" src={product.video_demo_link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              </p>
            </div>
            <div className="w-full lg:w-5/12 mb-4 bg-white product-section-shadow p-8">
            <h3 className="text-2xl  font-monta flex justify-start gap-2 items-center pb-2"> <FaFileSignature />Caractéristiques</h3>
            <p className="text-gray-700">{product.features}</p>
            </div>
          </div>

          {/* Similar Products */}
          <div className="p-6">
            {(similarProducts) ?
            <h2 className="text-3xl font-bold mb-6">Autres produits similaires</h2>
          : <span></span>}
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {
                (similarProducts) ? 
                similarProducts.map((prod, index) => (
                  <a href={`/products/${prod.products_id}`} key={index} className="bg-white p-4 shadow rounded-lg">
                    <img
                      src={"https://docuverse.tn/public/SiteImages/products/"+ prod.main_pic_link}
                      alt={prod.name}
                      className="w-full h-40 object-cover"
                    />
                    <h3 className="text-lg font-semibold mt-2">{prod.name}</h3>
                    <p className="text-gray-600">{prod.promo_price ? <div><del>{prod.price} TND</del> <span>{prod.promo_price}</span> </div>: <span>{prod.promo_price}</span>} TND</p>
                  </a>
                ))


                : <span></span>
              }
            </div>
          </div>
        </div>
      </div>
      <script src="https://unpkg.com/@material-tailwind/html@latest/scripts/dialog.js"></script>
      <Footer />
    </LayoutMain >
  );
};