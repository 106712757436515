import React from 'react'
import ComposedTitle from '../../../Components/ComposedTitle'
import { motion } from "framer-motion";
import BubbleText from '../../../Components/BubbleText/BubbleText';

export default function AboutSection() {
 
  return (
    <div className='bg-packs-section'>
        <div className='2xl:max-w-7xl lg:max-w-5xl w-[95%] m-auto py-24'>
          <ComposedTitle title={"À PROPOS DE NOTRE MARQUE"} subTitle={"DOCUVERSE"} />
          <div className="flex flex-col md:flex-row items-center justify-center p-8 ">
      <div className="relative lg:w-[60%] w-full lg:h-[850px]">
        <div className="lg:absolute lg:top-44 lg:right-0 lg:w-[372px] lg:h-[472px] w-full bg-black transform lg:-rotate-6 lg:z-10 border-4 border-white ">
          <img src="/Background.png" alt="Imxage 1" className="w-full h-full object-cover" />
        </div>
        <div className="my-6  flex lg:hidden md:mt-0 md:ml-6">
        <p className="text-black-plus">
        Bienvenue sur DOCUVERSE 
Transformez vos ambitions en succès !
Chez DOCUVERSE, nous savons que chaque instant compte en classes préparatoires. C'est pourquoi nous avons réuni une collection exclusive des meilleurs livres, soigneusement choisis pour vous aider à exceller.

        </p>
      </div>
        <div className="lg:absolute lg:top-0 lg:left-0 lg:w-[372px] lg:h-[472px] bg-black transform lg:rotate-3 border-4 border-white">
          <img src="/Background.png" alt="Imxage 2" className="w-full h-full object-cover" />
        </div>
        <div className="lg:absolute lg:bottom-24 lg:left-0 lg:w-[320px] lg:h-[320px] bg-black transform lg:rotate-1 border-4 border-white">
          <img src="/Background.png" alt="Imxage 3" className="w-full h-full object-cover" />
        </div>
      </div>
      <div className="mt-2 lg:w-[40%] hidden lg:flex md:mt-0 md:ml-6">
        <p className="text-black-plus">
        Plus qu'une simple librairie, nous sommes votre partenaire dans la course aux concours. Découvrez des ressources incontournables, optimisez votre temps, et préparez-vous à franchir la ligne d'arrivée . Rejoignez notre communauté de passionnés et prenez une longueur d'avance !
        </p>
      </div>
    </div>
        <BubbleText label={"#NOUS AIDONS LES ÉTUDIANTS"} />
        </div>
    </div>
    )
}
