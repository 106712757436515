import { motion, useCycle } from "framer-motion"
import { useEffect, useRef } from "react";
import { useDimensions } from "./use-dimensions";
import { MenuToggle } from "./MenuToggle";
import {Navigation} from './Navigation';
import { Navbar, Typography } from "@material-tailwind/react";


const sidebar = {
    open: (height = 1000) => ({
      clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
      transition: {
        type: "spring",
        stiffness: 20,
        restDelta: 2
      }
    }),
    closed: (width = 1920) => ({
      clipPath: `circle(0px at ${width}px 40px)`,
      transition: {
        delay: 0.5,
        type: "spring",
        stiffness: 400,
        damping: 40
      }
      }),
  };
  
  export const LiquidNav = () => {
    const [isOpen, toggleOpen] = useCycle(false, true);
    const containerRef = useRef(null)
    const { height } = useDimensions(containerRef);
    const { width } = useDimensions(containerRef);
    
    useEffect(() => {
      if (isOpen) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    }, [isOpen]);
    return (
      <Navbar className="sticky top-0 z-10 h-max max-w-full bg-transparent shadow-none backdrop-filter-none rounded-none border-none px-4 py-2 lg:px-8 lg:py-4">
      <div className="flex items-center justify-between  ">
        <Typography
          as="a"
          href="/"
          className="mr-4   py-1.5 text-3xl font-black text-text-white-plus uppercase"
        >
          Docuverse
        </Typography>
        <div className="flex items-center gap-4 bg-black">


      <motion.nav
        initial={false}
        animate={isOpen ? "open" : "closed"}
        custom={height}
        ref={containerRef}
        id="nav-bar"
        className="overflow-hidden w-full h-full"
      >
        <motion.div className="fixed top-0 right-0 bottom-0 left-0 w-full h-full bg-C1" variants={sidebar} />
        <Navigation />
        <MenuToggle toggle={() => toggleOpen()} />
      </motion.nav>

</div>
        </div>
       
      </Navbar>

    );
  };
  