import React from 'react'
import InfoBar from '../../Components/header/info-bar/InfoBar'
import ShuffleHero from '../../Components/header/shuffle-hero/ShuffleHero'
import Footer from '../../Components/footer/Footer'
import PacksSection from './components/PacksSection'
import AboutSection from './components/AboutSection'
import SectionTextImage from './components/SectionTextImage'
import SectionsSection from './components/SectionsSection'
import WhyUs from './components/WhyUs'
import FAQ from './components/FAQ'
import LayoutMain from "../LayoutMain"
export default function Home() {
  return (
    <LayoutMain>

    <div className='bg-white-plus'>
    <InfoBar />
    <ShuffleHero />
    <PacksSection/>
    <AboutSection />
    <SectionTextImage />
    <SectionsSection />
    <WhyUs/>
    <FAQ/>
    <Footer />
  </div>
    </LayoutMain>
  )
}
