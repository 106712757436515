import React, { useEffect, useRef, useState } from 'react'
import LinkBox from '../../../Components/link-box/LinkBox'
import { motion } from "framer-motion";
import { FaArrowRight } from 'react-icons/fa6'

export default function SectionTextImage() {
  return (
    <div>
      <section className="w-full  grid grid-cols-1 md:grid-cols-2 items-center gap-8  mx-auto bg-C1 bg-text-image">
          <div className="lg:px-24 px-8 py-12">
            <h3 className="text-6xl font-black font-monta text-text-white-plus">
            Plongez dans un monde rempli de documents authentiques. </h3>
            
            <div className="gap-3 flex mt-2">
              <a href='/products' className="border-text-white-plus border-[1px] flex justify-around items-center gap-6 text-text-white-plus  py-2 px-4 transition-all hover:bg-white-plus/30 duration-300 active:scale-95 rounded-2xl font-monta ">
              Consultez notre boutique
                <FaArrowRight />
              </a>
            </div>
          </div>
          <ShuffleGrid />
        </section>
    </div>
  )
}

const shuffle = (array) => {
    let currentIndex = array.length,
      randomIndex;
  
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
  
    return array;
  };
  
  const squareData = [
    {
      id: 1,
      src: "./book-smaple.png",
    },
    {
      id: 2,
      src: "./background.png",
    },
    {
      id: 3,
      src: "./book-smaple.png",
    },
    {
      id: 4,
      src: "./background.png",
    },
    {
      id: 5,
      src: "./book-smaple.png",
    },
    {
      id: 6,
      src: "./background.png",
    },
    {
      id: 7,
      src: "./book-smaple.png",
    },
    {
      id: 8,
      src: "./background.png",
    }
  ];
  
  const generateSquares = () => {
    return shuffle(squareData).map((sq) => (
      <motion.div
        key={sq.id}
        layout
        transition={{ duration: 1.5, type: "ease" }}
        className="w-full h-full"
        style={{
          backgroundImage: `url(${sq.src})`,
          backgroundSize: "cover",
        }}
      ></motion.div>
    ));
  };
  
  const ShuffleGrid = () => {
    const timeoutRef = useRef(null);
    const [squares, setSquares] = useState(generateSquares());
  
    useEffect(() => {
      shuffleSquares();
  
      return () => clearTimeout(timeoutRef.current);
    }, []);
  
    const shuffleSquares = () => {
      setSquares(generateSquares());
  
      timeoutRef.current = setTimeout(shuffleSquares, 3000);
    };
  
    return (
      <div className="grid lg:grid-cols-4 grid-cols-1 grid-rows-2 lg:h-[450px] h-[300px] gap-1">
        {squares.map((sq) => sq)}
      </div>
    );
  };
  
