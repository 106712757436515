import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './modules/home';
import Products from './modules/products/all';
import ProductDetail from './modules/products/details/ProductDetail';
import LayoutMain from './modules/LayoutMain';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <LayoutMain>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/products' element={<Products />} />
          <Route exact path='/products/:id' element={<ProductDetail />} />

        </Routes>
      </LayoutMain>

    </BrowserRouter>
  </React.StrictMode>
);

/*

*/