import React, { useEffect, useState } from 'react'
import {motion} from 'framer-motion'
import ComposedTitle from '../../../Components/ComposedTitle'
import { FaArrowRight } from 'react-icons/fa6'

export default function FAQ() {
    
    const faqs = [
        {
            label: "Est-ce que vous faites la livraison uniquement à Sfax ?",
            desc: "Non, nous livrons dans toute la Tunisie, y compris à Sfax.",
            show: false
        },
        {
            label: "Combien coûte la livraison ?",
            desc: "La livraison coûte 7 dinars pour toute la Tunisie. Cependant, elle est gratuite si vous passez une commande de plus de 250 dinars d'achats.",
            show: true
        },
        {
            label: "Les livres sont-ils toujours disponibles ?",
            desc: "Oui, nos livres sont de haute qualité et toujours en stock. Passez simplement votre commande et accédez aux meilleurs ouvrages.",
            show: false
        },
        {
            label: "Est-ce que j'ai encore besoin de m'inscrire dans des études privés ?",
            desc: "Non, avec nos livres et une bonne dose de sérieux, vous n'avez pas besoin d'études privés. Assurez-vous simplement de rester à jour.",
            show: false
        },
        {
            label: "Dans combien de temps puis-je recevoir ma commande ?",
            desc: "Vous recevrez votre commande dans un délai de 48 heures.",
            show: false
        },
        {
            label: "Comment puis-je voir le livre avant de l'acheter ?",
            desc: "Vous pouvez parcourir le livre en cliquant sur la page du livre correspondant. Vous y trouverez une description détaillée ainsi qu'un échantillon pour vous donner un aperçu du contenu avant de faire votre achat.",
            show: false
        }
    ]

    const [Data, setData] = useState(faqs)

 
    const setShowDetails = (index)=>{
        setData((Data) =>
            Data.map((item, i) =>
                i === index ? { ...item, show: !item.show } : item
            )
        );
    }

  
  return (
    <div>
          <div className='bg-packs-section border-b-8 border-C2'>
      <div className='2xl:max-w-7xl max-w-5xl m-auto py-24'>
        <ComposedTitle title={"FAQ"} subTitle={"got a question ?"} />
            <div className='flex gap-12 mt-8 justify-center items-center'>
                <div className='flex flex-col'>
                    {Data.map((faq, index)=>(
                        <motion.a 
                        whileHover={{ scale: 1.05}}
                        whileTap={{ scale: 0.99 }}
                        transition={{duration:1, type:'spring'}}
                        key={index}
                        onClick={()=>{setShowDetails(index)}}
                         className={`flex justify-around items-start flex-col select-none  max-w-[90%] lg:mx-0 mx-auto lg:max-w-[900px] py-3 lg:min-w-[900px] mb-4 px-10 gap-4  w-fit text-C2 bg-C2/5 border-C2   font-medium font-monta  border-[1px] rounded-xl `}>
                            <div className='flex justify-between items-center w-full text-xl gap-4'>
                            {faq.label}
                            <FaArrowRight />
                            </div>
                         <p className={faq.show? 'flex' : 'hidden'}> {faq.desc} </p>   
                        </motion.a>
                    ))}
                </div>
                
                <div className='xl:flex hidden'>
                    <img src="/stuck.png" alt="" />
                </div>
            </div>
    </div>
    </div>
    </div>
  )
}
