import React from 'react'
import { motion } from "framer-motion";

import { FaArrowRight } from "react-icons/fa6";

export default function LinkBox(prop,{theme = null}) {
  let themeClass;
  if(prop.theme != null){
    if(prop.theme === 'blue'){
      themeClass = "text-C2 bg-C2/5 border-C2"
    }else{
      themeClass = "text-white bg-white/5 border-white"
    }
  }else{
    themeClass = "text-white bg-white/5 border-white"
  }
  return (
    <div className='my-4'>
        <motion.a 
        whileHover={{ scale: 1.1, translateX: 20, rotate: 3}}
        whileTap={{ scale: 0.9 }}
        transition={{duration:1, type:'spring'}}
        href={prop.link} className={`flex justify-around items-center mb-4 px-10 gap-4 py-1 w-fit ${themeClass} text-lg  font-light font-monta  border-[0.5px] rounded-xl `}>
            {prop.label}
            <FaArrowRight />
        </motion.a>
    </div>
  )
}
