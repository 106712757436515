import React from "react";
import styles from "./bubble.module.css";

 

const BubbleText = (props) => {
  return (
    <h2 className="text-center lg:text-8xl text-5xl font-thin text-C2">
      {props.label.split("").map((child, idx) => (
        <span className={`font-monta select-none ${styles.hoverText}`} key={idx}>
          {child}
        </span>
      ))}
    </h2>
  );
};

export default BubbleText