import React from 'react'

export default function ComposedTitle(prop) {
  return (
    <div className='flex flex-col justify-start items-start p-2 lg:p-0'>
      <h1 className='font-monta font-black lg:text-5xl text-4xl text-black-plus uppercase'>{prop.title}</h1>
      <h1 className='font-monta font-black lg:text-5xl text-4xl text-light-grey uppercase'>{prop.subTitle}</h1>
    </div>
  )
}
