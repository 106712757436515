// useShoppingCart.js
import { useState } from 'react';

const useShoppingCart = () => {
  const [openCart, setOpenCart] = useState(false);

  const toggleShoppingCart = () => {
    loadCart();
    setOpenCart(!openCart);
  };


  const [Cart, setCart] = useState([])

  const getCartItems = ()=>{
    return Cart;
  }
  const getCartLength = ()=>{
    return Cart.length;
  }

  const addProductQte = (id) => {
    let localCart = localStorage.getItem('shoppingCart');
    let shoppingCartx = localCart ? JSON.parse(localCart) : [];
    shoppingCartx = shoppingCartx.map(item =>
      item.id === id ? { ...item, qte: item.qte + 1 } : item
    );
    localStorage.setItem('shoppingCart', JSON.stringify(shoppingCartx));
    loadCart();
  };
  const decProductQte = (id) => {
    let localCart = localStorage.getItem('shoppingCart');
    let shoppingCart = localCart ? JSON.parse(localCart) : [];
    shoppingCart = shoppingCart.map(item =>
      item.id === id ? { ...item, qte: (item.qte - 1) > 0 ? (item.qte - 1) : 1 } : item
    );
    localStorage.setItem('shoppingCart', JSON.stringify(shoppingCart));
    loadCart();
  };
  const removeProductFromCart = (id) => {

    let localCart = localStorage.getItem('shoppingCart');
    let shoppingCart = localCart ? JSON.parse(localCart) : [];
    shoppingCart = shoppingCart.filter(
      (item) => item.id !== id
    );
    localStorage.setItem('shoppingCart', JSON.stringify(shoppingCart));
    loadCart();

  };
  const calcCartSum = () => {
    let selectedItems = Cart.filter((item) => item.qte >= 1);
    let sum = 0
    selectedItems.forEach(item => {
      sum += item.price * item.qte;
    })
    if(sum<=250 && sum>0){
      sum += 7;
    }
    return sum;
  }

  const loadCart = () => {
    let shoppingCart = localStorage.getItem('shoppingCart');
    setCart((shoppingCart != null && shoppingCart !== undefined) ? JSON.parse(shoppingCart) : []);
  }

  const [openCheckout, setOpenCheckout] = useState(false);

  const toggleCheckout = () => {
    if(getCartLength()>0){
      setOpenCheckout(!openCheckout);
    }
  };




  return {
    toggleShoppingCart,
    addProductQte,
    decProductQte,
    removeProductFromCart,
    calcCartSum,
    loadCart,
    toggleCheckout,
    getCartItems,
    getCartLength,
    openCart,
    setOpenCart,
    openCheckout,
    setOpenCheckout
    
  };
};

export default useShoppingCart;
